import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchorders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/get/orders', {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  
  
    addorders(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/add/orders', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
  
    updateorders(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/update/orderstatus', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteorders(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/delete/orders', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    addUser(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admins/registerAssociate', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
