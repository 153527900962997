import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useordersList() {

    const toast = useToast();


    const pageLength = ref(10);

    const csLength = ref(0);

    const columns = [
        {
            label: "orders",
            field: "orders",
        },

    ];

    var searchTerm = ref('');


    const orders = ref([]);

    const statusVariant = computed(() => {
        const statusColor = {
            1: "light-success",
            0: "light-danger",
        };
        return (status) => statusColor[status];
    })



    const fetchorders = async () => {
        store
            .dispatch('master-orders/fetchorders', {})
            .then(response => {
                
                if (response.data.result == 1) {
                 
                    if(csLength.value  != 0) {
                    
                        if (response.data.data.length != orders.value.length) {
                            var audio = new Audio('https://assets.mixkit.co/sfx/preview/mixkit-urgent-simple-tone-loop-2976.mp3');
                            audio.play();
                            
                        }
                    }
                    orders.value = response.data.data;
                    csLength.value ++;
                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching orders',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const fetchorders1 = async () => {
        store
            .dispatch('master-orders/fetchorders', {})
            .then(response => {
                
                if (response.data.result == 1) {
                    orders.value = response.data.data;
                    csLength.value ++;
                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching orders',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }



    const refetchorders = () => {
        fetchorders();
    }


    const addorders = async (formData) => {
        return store
            .dispatch('master-orders/addorders', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding orders',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }


    const updateorders = async (data) => {
        return store
            .dispatch('master-orders/updateorders', data)
            .then(response => {
                if (response.data.result == 1) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding orders',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const deleteorders = async (formData) => {
        return store
            .dispatch('master-orders/deleteorders', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding orders',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const addUser = async (formData) => {
        return store
            .dispatch('app-user/addUser', formData)
            .then(response => {
                console.log(response);
                if (response.data.success && response.data.success == true) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User added successfuly',
                            icon: 'CheckIcon',
                            variant: 'Success',
                        },
                    })
                    return true;
                } else {
                    return { error: response.data.status };
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error while adding user',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
                return false;
            })
    }



    return {
        fetchorders,
        fetchorders1,
        addorders,
        refetchorders,
        updateorders,
        deleteorders,
        columns,
        pageLength,
        searchTerm,
        orders,
        statusVariant,


    }
}