<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Orders List">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
          <div></div>

        
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="orders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'orders'" class="1">
              <div :class="`p-1 bg-color${props.row.delivery_type}  bg-color${props.row.order_status}`" >
                <div class="text-bld-10">
                  <div class="d-md-flex justify-content-between ">
                    <div>
                      <p>Date : {{ props.row.delivery_date }}</p>
                      <p>Time : {{ props.row.delivery_time }} </p>
                    </div>
                    <div>
                      <p>Name : {{ props.row.firstname }}</p>
                      <p>Order Type : {{ props.row.delivery_type }}</p>
                      <p v-if="props.row.persons">Persons : {{ props.row.persons }}</p>

                    </div>
                    <div>
                      <p>Order No : {{ props.row.order_number }} <!-- <span> <b-badge variant="danger"
                  class="ml-2 hover-pointer"
                    @click="initdelete(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                    
                  </b-badge></span> --></p>
                      <p>Payment Method : {{ props.row.payment_type }}</p>
                      <div>
                        <div>
                          <a
                            target="_blank"
                            :href="`https://yaal.ch/print.php/?order_no=${props.row.order_number}`"
                          >
                            <b-button
                              variant="gradient-primary"
                              class="mr-1 mb-1"
                              size="sm"
                              >Print</b-button
                            ></a
                          >
                          <a
                            target="_blank"
                            :href="`https://yaal.ch/printA4.php/?order_no=${props.row.order_number}`"
                          >
                            <b-button
                              variant="gradient-success"
                              class="mr-1 mb-1"
                              size="sm"
                              >Print A4</b-button
                            ></a
                          >
                          <!-- <b-button
                            variant="gradient-warning"
                            class="mr-1 mb-1"
                            size="sm"
                            >View</b-button
                          >
                          <b-button
                            variant="gradient-danger"
                            class="mr-1 mb-1"
                            size="sm"
                            >Delete</b-button
                          > -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <button class="btn btn-danger">Order Cancelled</button>
                  </div> -->
                  <div v-if="props.row.delivery_type == 'Delivery'">
                    <div class="my-5" v-if="props.row.order_status < 5">
                      <ol class="progtrckr" data-progtrckr-steps="5">
                        <li
                          :class="
                            props.row.order_status >= 0
                              ? 'progtrckr-done'
                              : 'progtrckr-todo'
                          "
                        >
                          New Order
                        </li>
                        <li
                          :class="
                            props.row.order_status >= 1
                              ? 'progtrckr-done'
                              : 'progtrckr-todo'
                          "
                        >
                          Confirmation
                        </li>
                        <li
                          :class="
                            props.row.order_status >= 2
                              ? 'progtrckr-done'
                              : 'progtrckr-todo'
                          "
                        >
                          Cooking
                        </li>
                        <li
                          :class="
                            props.row.order_status >= 3
                              ? 'progtrckr-done'
                              : 'progtrckr-todo'
                          "
                        >
                          Delivery
                        </li>
                        <li
                          :class="
                            props.row.order_status >= 4
                              ? 'progtrckr-done'
                              : 'progtrckr-todo'
                          "
                        >
                          End
                        </li>
                      </ol>
                    </div>
                    <div v-else>
                      <b-button
                        variant="gradient-danger"
                        class="mr-1 mb-1"
                        size="sm"
                        >Cancelled
                      </b-button>
                    </div>
                  </div>
                  <div class="">
                    <b-row>
                      <b-col
                        cols="4"
                        v-if="props.row.delivery_type == 'Delivery'"
                      >
                        <b-form-group>
                          <v-select
                            class="bg-white"
                            ref="vSelect"
                            :options="options"
                            :reduce="(country) => country.code"
                            v-model="props.row.order_status"
                            label="country"
                            @input="
                              change_status(
                                props.row.id,
                                props.row.order_status
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="2"
                        class="text-right"
                        v-if="props.row.delivery_type == 'Delivery'"
                      >
                        <b-form-group>
                          <button
                            @click="
                              getTime(
                                props.row.delivery_type,
                                props.row.delivery_date,
                                props.row.id
                              )
                            "
                            class="btn btn-dark"
                          >
                            Change Time
                          </button>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                        class=""
                        v-if="
                          props.row.delivery_type == 'Delivery' ||
                          props.row.delivery_type == 'Takeaway'
                        "
                      >
                        <p class="mr-5">Total : {{ parseFloat(props.row.amount).toFixed(2)  }} CHF</p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </span>
            <!-- Column: Action -->

            
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <div>
      <b-modal
        id="modal-lg"
        ok-only
        ok-title="Close"
        centered
        size="lg"
        title="Change Time"
        ref="reference1"
      >
        <div>
          <div>
            <h5 class="text-center">Morning</h5>
            <div>
              <ul class="list-unstyled d-flex flex-wrap">
                <li v-for="(m, a) in deltime1" class="m-1">
                  <b-button variant="outline-dark" @click="changeTime(m)">
                    {{ m }}
                  </b-button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h5 class="text-center">Evening</h5>
            <div>
              <ul class="list-unstyled d-flex flex-wrap">
                <li v-for="(m, a) in deltime2" class="m-1">
                  <b-button variant="outline-dark" @click="changeTime(m)">
                    {{ m }}
                  </b-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import useordersList from "./useordersList";
import ordersStoreModule from "../ordersStoreModule";
import vSelect from "vue-select";
import { color } from "echarts/lib/theme/light";
import axios from "@axios";
export default {
  async mounted() {
    await this.fetchorders();

    setInterval(this.fetchorders, 5000);
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },

  data() {
    return {
      options: [
        { code: "0", country: "New Order" },
        { code: "1", country: "Confirmation" },
        { code: "2", country: "Cooking" },
        { code: "3", country: "Delivery" },
        { code: "4", country: "End" },
        { code: "5", country: "Cancelled" },
      ],
      selected: "0",
      deltime1: null,
      deltime2: null,
      changeId: null,
    };
  },
  computed: {
    setBg(val) {
      let color = "";
      if (val == 0) {
        color = "bg-light-primary";
      }
      return color;
    },
  },
  methods: {
    async change_status(id, val) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Change it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {};
          data.id = id;
          data.val = val;
          var result = this.updateorders(data);
        } else {
          location.reload();
        }
      });
    },
    toFormData: function (obj) {
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },

    initdelete(id){
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.post(`https://admin.yaal.ch/api/user/deleteorder/${id}`).then((response) => {
          if (response.data.result == 1) {
            this.$swal({
            title: "Order Deleted",
            text: "Success",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
            this.fetchorders1();
          }
        });
        } 
      });
     
    },
    getTime(deliveryType, date, id) {
      this.changeId = id;
      var data = {
        order_type: deliveryType,
        deliveryDate: date,
      };

      data = this.toFormData(data);

      axios
        .post(`https://admin.yaal.ch/api/user/get/time`, data)
        .then((response) => {
          if (response.data.result == 1) {
            this.$refs["reference1"].show();
            this.deltime1 = response.data.time1;
            this.deltime2 = response.data.time2;
          }
        });
    },

    async initdeleteorders(val, type, status) {
      if (val) {
        let formData = {
          id: val,
          type: type,
          status: status,
        };
        var result = await this.deleteorders(formData);
        if (result == true) {
          this.refetchorders();
        }
      }
    },

    changeTime(time) {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Change it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {};
          data.id = this.changeId;
          data.time = time;
          axios
            .post(`https://admin.yaal.ch/api/admin/set/time`, data)
            .then((response) => {
              if (response.data.result == 1) {
                this.$refs["reference1"].hide();
                this.$swal({
                  title: "Delivery Time Changed",
                  text: "Success",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            });
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-orders";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, ordersStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });
    const isSidebarActive = ref(false);

    const {
      fetchorders,
      fetchorders1,
      refetchorders,
      deleteorders,
      updateorders,
      resetForm,
      columns,
      pageLength,
      searchTerm,
      orders,
      statusVariant,
    } = useordersList();

    return {
      fetchorders,
      fetchorders1,
      refetchorders,
      deleteorders,
      updateorders,
      resetForm,
      columns,
      pageLength,
      searchTerm,
      orders,
      statusVariant,
      isSidebarActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.text-bld-10 *{
  font-weight: 600;
}
</style>
